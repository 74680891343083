@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
html {
  background: #f0f4f9;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

header > .MuiToolbar-root.MuiToolbar-regular > .MuiBox-root {
  justify-content: unset;
}

a {
  color: #023365;
  text-decoration: none;
}

.support-comment .MuiOutlinedInput-multiline {
  height: auto !important;
}

.custom-drawer .MuiPaper-root {
  background-color: #f0f4f9 !important;
  border-right: 0;
  z-index: 1199;
}

.custom-drawer .MuiList-root {
  padding-top: 40px;
}

.sidebar-button .MuiButtonBase-root {
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.beta-font {
  background-color: #023365;
  color: #fff;
  padding: 2px 5px;
  border-radius: 7px;
}

.toggle-button .MuiButtonBase-root {
  background: #cbd5e0;
  border-radius: 0 8px 8px 0;
  padding: 0.5rem 1.5rem;
}

.custom-drawer-open {
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  width: 290px;
}

.custom-drawer-close {
  width: 57px;
  overflow-x: hidden;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

@media (min-width: 600px) {
  .custom-drawer-close {
    width: 73px;
  }
}

.custom-drawer-icon {
  color: #677784;
}

.custom-drawer-icon-selected {
  color: #023365;
}

.bottom-nav-bar-drawer-paper {
  background: white;
}

.custom-drawer .card {
  background: #fff !important;
  margin-left: 32px;
  margin-right: 48px;
}

.custom-drawer .card .tittle {
  color: #023365;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
}
.custom-drawer .card .detail {
  margin-top: 8px;
  color: #6d7781;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 136%;
}
.custom-drawer .card .active-button {
  text-transform: capitalize !important;
  color: #023365;
  border: 0.5px solid #023365;
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: 10px;
  margin-right: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
}
.custom-drawer .card .active-button .active {
  color: #aaffaa !important;
}

