.custom-drawer .card {
  background: #fff !important;
  margin-left: 32px;
  margin-right: 48px;
}

.custom-drawer .card .tittle {
  color: #023365;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
}
.custom-drawer .card .detail {
  margin-top: 8px;
  color: #6d7781;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 136%;
}
.custom-drawer .card .active-button {
  text-transform: capitalize !important;
  color: #023365;
  border: 0.5px solid #023365;
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: 10px;
  margin-right: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
}
.custom-drawer .card .active-button .active {
  color: #aaffaa !important;
}
