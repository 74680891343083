.custom-drawer .MuiPaper-root {
  background-color: #f0f4f9 !important;
  border-right: 0;
  z-index: 1199;
}

.custom-drawer .MuiList-root {
  padding-top: 40px;
}

.sidebar-button .MuiButtonBase-root {
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.beta-font {
  background-color: #023365;
  color: #fff;
  padding: 2px 5px;
  border-radius: 7px;
}

.toggle-button .MuiButtonBase-root {
  background: #cbd5e0;
  border-radius: 0 8px 8px 0;
  padding: 0.5rem 1.5rem;
}

.custom-drawer-open {
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  width: 290px;
}

.custom-drawer-close {
  width: 57px;
  overflow-x: hidden;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

@media (min-width: 600px) {
  .custom-drawer-close {
    width: 73px;
  }
}

.custom-drawer-icon {
  color: #677784;
}

.custom-drawer-icon-selected {
  color: #023365;
}

.bottom-nav-bar-drawer-paper {
  background: white;
}
